import { sprinkles } from '@styles/sprinkles.css'
import { HeroCards } from './HeroCards/HeroCards'
import { RecentFiles } from './RecentFiles'

export const Home = () => (
  <>
    <HeroCards />
    <h3 className={sprinkles({ marginBottom: 8, display: 'flex', gap: 8, alignItems: 'center' })}>
      Your Recent Workspaces
    </h3>
    <RecentFiles />
  </>
)
