import { Hero } from '@components/ui/Hero'
import { sprinkles } from '@styles/sprinkles.css'

export const HeroCards = () => (
  <div className={sprinkles({ display: 'flex', flex: 'grow', gap: 4, marginBottom: 32, backgroundColor: 'gray-1' })}>
    <Hero heading={'Welcome to Altana'} className={sprinkles({ width: '100%' })} background="gray">
      <div
        className={sprinkles({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        })}
      >
        <p>The only dynamic, intelligent map of the global supply chain.</p>
      </div>
    </Hero>
  </div>
)
