import { ReactElement } from 'react'
import { AppLayout, PageTitlePortal } from '@layouts/AppLayout'
import { NextPageWithLayout } from '@layouts/NextPageWithLayout'
import { PageContent } from '@layouts/AppLayout/AppLayout.css'
import { Home } from '@components/Home'

const HomePage: NextPageWithLayout = () => <Home />

HomePage.getLayout = (page: ReactElement) => (
  <AppLayout>
    <PageTitlePortal title="Home" />
    <div className={PageContent}>{page}</div>
  </AppLayout>
)

export default HomePage
