import { SvgVariants } from '@components/ui/SvgImage'
import { sprinkles } from '@styles/sprinkles.css'
import clsx from 'clsx'
import { gridBg, vcBg, grayBg } from './Hero.css'

type HeroProps = {
  heading: string | JSX.Element
  icon?: keyof SvgVariants
  background?: 'grid' | 'vc' | 'gray'
  className?: string
  children?: JSX.Element
}

export const Hero = ({ children, className, background = 'gray', heading }: HeroProps) => (
  <div className={clsx(styledHero, backgroundMap[background], className)}>
    <h1 className={heroHeading}>{heading}</h1>
    <>{children}</>
  </div>
)

const backgroundMap = {
  grid: gridBg,
  vc: vcBg,
  gray: grayBg,
}

const styledHero = sprinkles({
  display: 'flex',
  borderRadius: 8,
  padding: 32,
  flexDirection: 'column',
  minHeight: 256,
})

const heroHeading = sprinkles({
  fontSize: 24,
  fontWeight: 600,
})
